import React from "react";
import FadeIn from "react-fade-in";
import Button from "react-bootstrap/Button";
import "./landing.css";

function LandingPage() {
  return (
    <div className="App">
      <header className="App-header">
        <FadeIn>
          <h3>Dan Smith</h3>
          <p className="App-paragraph">contact@dan.sm</p>
          <p className="App-small">
            PGP: <code>47f2fde1b672dcc19d859094ad2df9a06afe09bf</code>
          </p>
          <Button className="btn btn-dark btn-sm"
            onClick={() => {
              window.location.href = "publickey.contact@dan.sm.asc";
            }}
          >
            Download Public Key
          </Button>
        </FadeIn>
      </header>
    </div>
  );
}

export default LandingPage;
