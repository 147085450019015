import React from "react";
import "./scooter.css";

function ScooterPrivacyPage() {
  return (
    <div className="App">
      <header className="App-header">
      <h2>Privacy Notice</h2>
          <p className="App-paragraph">
              Thank you for choosing to be part of our community at University of Southampton ("Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at dos1g17@ecs.soton.ac.uk.
          </p>
          <p className="App-paragraph">
              When you use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include the App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
          </p>
          <p className="App-paragraph">
              This privacy notice applies to all information collected through our Services (which, as described above, includes our App), as well as, any related services, sales, marketing or events.
          </p>
          <p className="App-paragraph">
              <b>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</b>
          </p>
          <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
          <p className="App-paragraph">
              <b>Personal information you disclose to us</b>
          </p>
          <p className="App-paragraph">
              We collect personal information that you voluntarily provide to us when you register on the App, express an interest in obtaining information about us or our products and Services, when you participate in activities on the App or otherwise when you contact us.
          </p>
          <p className="App-paragraph">
              The personal information that we collect depends on the context of your interactions with us and the App, the choices you make and the products and features you use. The personal information we collect may include the following:
          </p>
          <p className="App-paragraph">
              Personal Information Provided by You. We collect email addresses; and other similar information.
          </p>
          <p className="App-paragraph">
              Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/en-gb/privacy.
          </p>
          <p className="App-paragraph">
              All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
          </p>
          <p className="App-paragraph">
              <b>Information collected through our App</b>
          </p>
          <p className="App-paragraph">
              If you use our App, we also collect the following information:
          </p>
          <p className="App-paragraph">
              Geolocation Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
          </p>
          <p className="App-paragraph">
              Mobile Device Data. We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed.
          </p>
          <p className="App-paragraph">
              Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
          </p>
          <p className="App-paragraph">
              This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
          </p>
          <p className="App-paragraph">
              <b>Information collected from other sources</b>
          </p>
          <p className="App-paragraph">
              In order to enhance our ability to provide relevant marketing, offers and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, as well as from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs and custom profiles, for purposes of targeted advertising and event promotion.
          </p>
          <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
          <p className="App-paragraph">
              We use personal information collected via our App for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
          </p>
          <p className="App-paragraph">
              We use the information we collect or receive:
          </p>
          <p className="App-paragraph">
              To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
          </p>
          <p className="App-paragraph">
              To post testimonials. We post testimonials on our App that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at contact@dan.sm and be sure to include your name, testimonial location, and contact information.
          </p>
          <p className="App-paragraph">
              Request feedback. We may use your information to request feedback and to contact you about your use of our App.
          </p>
          <p className="App-paragraph">
              To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.
          </p>
          <p className="App-paragraph">
              To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
          </p>
          <p className="App-paragraph">
              To protect our Services. We may use your information as part of our efforts to keep our App safe and secure (for example, for fraud monitoring and prevention).
          </p>
          <p className="App-paragraph">
              To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.
          </p>
          <p className="App-paragraph">
              To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
          </p>
          <p className="App-paragraph">
              To deliver and facilitate delivery of services to the user. We may use your information to provide you with the requested service.
          </p>
          <p className="App-paragraph">
              To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
          </p>
          <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
          <p className="App-paragraph">
              We may process or share your data that we hold based on the following legal basis:
          </p>
          <p className="App-paragraph">
              Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
          </p>
          <p className="App-paragraph">
              Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
          </p>
          <p className="App-paragraph">
              Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
          </p>
          <p className="App-paragraph">
              Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
          </p>
          <p className="App-paragraph">
              Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
          </p>
          <p className="App-paragraph">
              More specifically, we may need to process your data or share your personal information in the following situations:
          </p>
          <p className="App-paragraph">
              Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Place API). To find out more about Google’s Privacy Policy, please refer to this <a href="https://policies.google.com/privacy">link</a>.
          </p>
          <h4>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
          <p className="App-paragraph">
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
          </p>
          <h4>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
          <p className="App-paragraph">
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
          </p>
          <p className="App-paragraph">
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
          </p>
          <h4>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
          <p className="App-paragraph">
              We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.
          </p>
          <h4>7. DO WE COLLECT INFORMATION FROM MINORS?</h4>
          <p className="App-paragraph">
              We do not knowingly solicit data from or market to children under 18 years of age. By using the App, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the App. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at contact@dan.sm.
          </p>
          <h4>8. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
          <p className="App-paragraph">
              In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
          </p>
          <p className="App-paragraph">
              If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
          </p>
          <p className="App-paragraph">
              If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
          </p>
          <p className="App-paragraph">
          If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
          </p>
          <p className="App-paragraph">
              If you have questions or comments about your privacy rights, you may email us at dos1g17@ecs.soton.ac.uk.
          </p>
          <p className="App-paragraph">
              <b>Account Information</b>
          </p>
          <p className="App-paragraph">
              If you would at any time like to review or change the information in your account or terminate your account, you can contact us using the contact information provided.
          </p>
          <p className="App-paragraph">
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
          </p>
          <p className="App-paragraph">
              Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may contact us using the contact information provided.
          </p>
          <h4>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
          <p className="App-paragraph">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. 
          </p>
          <h4>10. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
          <p className="App-paragraph">
              We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
          </p>
          <h4>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
          <p className="App-paragraph">
          If you have questions or comments about this notice, you may email us at: <a href="mailto:dos1g17@ecs.soton.ac.uk">dos1g17@ecs.soton.ac.uk</a>.
          </p>
      </header>
    </div>
  );
}

export default ScooterPrivacyPage;
