import React from "react";
import FadeIn from "react-fade-in";
import Button from "react-bootstrap/Button";
import "./scooter.css";

function ScooterSupportPage() {
  return (
    <div className="App">
      <header className="App-header">
        <FadeIn>
          <h3>Skrrt v1.0 (3)</h3>
          <p className="App-paragraph">
              Please direct any queries to <a href="mailto:contact@dan.sm">contact@dan.sm</a>, or call +44 23 8047 5675.
          </p>
        </FadeIn>
      </header>
    </div>
  );
}

export default ScooterSupportPage;
