import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./Landing";
import ScooterSupport from "./Scooter/support";
import ScooterPrivacy from "./Scooter/privacy";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/scooter2021support">
            <ScooterSupport />
          </Route>
          <Route path="/scooter2021privacy">
            <ScooterPrivacy />
          </Route>
          <Route
            path="/scooter2021"
            component={() => {
              window.location.href =
                "https://apps.apple.com/us/app/skrrt/id1544619717";
              return null;
            }}
          />
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
